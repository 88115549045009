import { ReactNode, createContext, useEffect, useState } from 'react'
// import { BookEntity, BookEntityGroup } from 'hungrywormsdk'
import { BookEntityGroup, BookEntity } from '../gql/graphql'
import { client } from '../apollo'
import { GET_BOOK_ENTITIES, GET_ENTITY_GROUPS } from '../utils/queries'

export interface EditorContextType {
    bookId: number | undefined
    entities: BookEntity[]
    entityGroup: number | undefined
    setEntityGroup: (val: number | undefined) => void
    groups: BookEntityGroup[]
    updateBookEntity: (id: number, val: BookEntity) => void
    deleteBookEntity: (id: number) => void
    fetchBookEntities: () => Promise<void>
    fetchEntityGroups: () => Promise<void>
    updateBookEntityGroup: (id: number, val: BookEntityGroup) => void
}

export const EditorContext = createContext<EditorContextType>({
    bookId: undefined,
    entities: [],
    entityGroup: undefined,
    setEntityGroup: () => {},
    groups: [],
    updateBookEntity: () => {},
    //@ts-ignore
    fetchBookEntities: () => {},
    //@ts-ignore
    fetchEntityGroups: () => {},
    //@ts-ignore
    updateBookEntityGroup: () => {},
})

export function EditorProvider({
    bookId,
    children,
}: {
    bookId: number | undefined
    children?: ReactNode
}) {
    const [entities, setEntities] = useState<BookEntity[]>([])
    const [entityGroup, setEntityGroup] = useState<number | undefined>(
        undefined
    )

    const [groups, setGroups] = useState<BookEntityGroup[]>([])

    // Fetch book information
    useEffect(() => {
        if (bookId === undefined) {
        } else {
            fetchBookEntities()
            fetchEntityGroups()
        }
        // eslint-disable-next-line
    }, [bookId])

    async function fetchBookEntities() {
        if (!bookId) return
        const res = await client.query({
            query: GET_BOOK_ENTITIES,
            variables: {
                id: bookId,
            },
        })
        // res.data.bookEntities
        setEntities([...res.data.bookEntities])
    }

    function updateBookEntity(id: number, val: BookEntity) {
        setEntities((prev) => {
            return prev.map((item) => {
                return item.id === id ? { ...val } : item
            })
        })
    }

    function updateBookEntityGroup(id: number, val: BookEntityGroup) {
        setGroups((prev) => {
            return prev.map((item) => {
                return item.id === id ? { ...val } : item
            })
        })
    }

    async function fetchEntityGroups() {
        if (!bookId) return
        const res = await client.query({
            query: GET_ENTITY_GROUPS,
            variables: {
                id: bookId,
            },
        })
        setGroups([...res.data.bookEntityGroups])
    }

    function deleteBookEntity(id: number) {
        setEntities((prev) => {
            const index = prev.findIndex((item) => item.id === id)
            console.log('deleting index: ', index)
            if (index < 0) return prev

            const copy = [...prev]
            copy.splice(index, 1)
            return copy
        })
    }

    // function updateBookEntityGroup(id: number, val: BookEntityGroup) {
    //     setGroups((prev) => {
    //         return prev.map((item) => {
    //             return item.id === id ? { ...val } : item
    //         })
    //     })
    // }

    return (
        <EditorContext.Provider
            value={{
                bookId,
                entities,
                entityGroup,
                setEntityGroup,
                groups,
                updateBookEntity,
                fetchEntityGroups,
                deleteBookEntity,
                fetchBookEntities,
                updateBookEntityGroup,
            }}
        >
            {children}
        </EditorContext.Provider>
    )
}

import { useEffect, useRef, useState } from 'react'
import { GetPlaceDetailsQuery } from '../../gql/graphql'

import { client } from '../../apollo'
import { getPhotoUrl } from '../../utils/placesApi'
import { GET_PLACE_DETAILS } from '../../utils/queries'

export function GooglePlaceDetails({ placeId }: { placeId: string }) {
    const imgRef = useRef<HTMLImageElement>(null)

    const [details, setDetails] =
        useState<GetPlaceDetailsQuery['groupDetails']>()

    useEffect(() => {
        if (!details?.googleImages || !imgRef.current) return
        imgRef.current.src = getPhotoUrl(details.googleImages[0])
    }, [details])

    useEffect(() => {
        client
            .query({
                query: GET_PLACE_DETAILS,
                variables: {
                    id: placeId,
                },
            })
            .then((res) => {
                if (res.data.groupDetails) {
                    setDetails(res.data.groupDetails)
                }
            })
    }, [placeId])

    if (!details) {
        return <p>Loading details..</p>
    }

    return (
        <div className="flex flex-col">
            <img ref={imgRef} alt="cover" className="h-48 object-cover" />
            {/* <p className="text-xl">{details.displayName}</p> */}
            <p className="font-bold text-xs">{details.address}</p>
            <p>{details.rating}</p>
            <p className="font-light">{details.description}</p>
            {details.googleurl && (
                <a href={details.googleurl} target="new">
                    open in Maps
                </a>
            )}
        </div>
    )
}

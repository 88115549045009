import React, { useState } from 'react'
// import { useMutation } from '@apollo/client'
// import { CREATE_BOOK } from '../utils/queries' // You'll need to create this mutation

interface CreateBookModalProps {
    isOpen: boolean
    onClose: () => void
}

export function CreateBookModal({ isOpen, onClose }: CreateBookModalProps) {
    const [title, setTitle] = useState('')
    const [authorName, setAuthorName] = useState('')
    // const [createBook] = useMutation(CREATE_BOOK)

    if (!isOpen) return null

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            // await createBook({ variables: { title, authorName } })
            onClose()
            // Optionally, refetch the book list or update the cache here
        } catch (error) {
            console.error('Error creating book:', error)
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <h3 className="text-lg font-bold mb-4">Create New Book</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Book Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full p-2 mb-4 border rounded"
                    />
                    <input
                        type="text"
                        placeholder="Author Name"
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                        className="w-full p-2 mb-4 border rounded"
                    />
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

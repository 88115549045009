import { FormLabel } from '../../components/FormLabel'
import { ButtonPrimary } from '../../components/themed'
import { signInWithGoogle } from '../../utils/signInWithGoogle'
import { AuthBody } from './AuthBody'
import { useNavigate } from 'react-router-dom'
import { signInWithEmail } from '../../utils/signInWithEmail'
import { useState } from 'react'
import { signInWithX } from '../../utils/signInWithX'

export function Login() {
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')

    function onSuccess() {
        navigate('/editor/books')
    }

    async function emailLogin() {
        try {
            await signInWithEmail(email, pass)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <AuthBody>
            <div className="flex flex-col w-full">
                <div className="flex flex-col w-full">
                    <FormLabel>Email</FormLabel>
                    <input
                        className="p-2 border w-full"
                        type="email"
                        value={email}
                        onChange={(val) => {
                            setEmail(val.target.value)
                        }}
                    ></input>
                </div>
                <div className="flex flex-col my-2 w-full">
                    <FormLabel>Password</FormLabel>
                    <input
                        className="p-2 border w-full"
                        type="password"
                        value={pass}
                        onChange={(val) => {
                            setPass(val.target.value)
                        }}
                    ></input>
                </div>
                <ButtonPrimary type="solid" onClick={emailLogin}>
                    Login
                </ButtonPrimary>
                <div className="flex flex-row justify-center mt-3">
                    <p>Dont have an account?</p>
                    <a href="/auth/signup" className="ml-3 text-green-500">
                        Signup
                    </a>
                </div>
            </div>
            <div className="my-5 w-full">
                <TextDivider text="or"></TextDivider>
            </div>
            <div className="w-full">
                <SocialLogin
                    text="Sign in with Google"
                    iconUrl="https://cdn-icons-png.flaticon.com/512/2702/2702602.png"
                    onClick={() => {
                        signInWithGoogle(onSuccess)
                    }}
                    style={{ marginBottom: '10px' }}
                ></SocialLogin>
                <SocialLogin
                    text="Sign in with Apple"
                    iconUrl="https://cdn-icons-png.flaticon.com/512/0/747.png"
                    onClick={() => {
                        // TODO: Implement Apple sign-in
                        console.log('Apple sign-in clicked')
                    }}
                    style={{ marginBottom: '10px' }}
                ></SocialLogin>

                <SocialLogin
                    text="Sign in with Facebook"
                    iconUrl="https://cdn-icons-png.flaticon.com/512/124/124010.png"
                    onClick={() => {
                        // TODO: Implement Facebook sign-in
                        console.log('Facebook sign-in clicked')
                    }}
                    style={{ marginBottom: '10px' }}
                ></SocialLogin>
                <SocialLogin
                    text="Sign in with X"
                    iconUrl="https://cdn-icons-png.flaticon.com/512/5969/5969020.png"
                    onClick={() => {
                        // TODO: Implement X (formerly Twitter) sign-in
                        signInWithX().then(() => {})
                    }}
                    style={{ marginBottom: '10px' }}
                ></SocialLogin>
            </div>
        </AuthBody>
    )
}

function TextDivider({ text }: { text: string }) {
    return (
        <div className="flex flex-row w-full items-center">
            <div className="flex-grow border-b h-0"></div>
            <p className="px-3">{text}</p>

            <div className="flex-grow border-b h-0"></div>
        </div>
    )
}

function SocialLogin({
    onClick,
    iconUrl,
    text,
    style,
}: {
    onClick?: any
    iconUrl?: string
    text?: string
    style?: React.CSSProperties
}) {
    return (
        <div
            className="border p-4 px-6 w-full flex flex-row items-center rounded cursor-pointer"
            onClick={onClick}
            style={style}
        >
            <img
                className="mr-3"
                src={iconUrl}
                style={{
                    width: '20px',
                    height: '20px',
                }}
                alt="social login"
            />
            <p>{text}</p>
        </div>
    )
}

import React from 'react'

interface Tab {
    value: string
    label: string
}

interface TabSelectorProps {
    tabs: Tab[]
    activeTab: string
    onTabChange: (value: string) => void
}

export function TabSelector({
    tabs,
    activeTab,
    onTabChange,
}: TabSelectorProps) {
    return (
        <div className="flex justify-center mb-8">
            <div className="inline-flex rounded-lg shadow-sm">
                {tabs.map((tab) => (
                    <button
                        key={tab.value}
                        className={`px-6 py-3 text-sm font-medium focus:outline-none transition-colors duration-200 ${
                            activeTab === tab.value
                                ? 'bg-blue-500 text-white'
                                : 'bg-white text-gray-700 hover:bg-gray-50'
                        } ${
                            tab.value === tabs[0].value ? 'rounded-l-lg' : ''
                        } ${
                            tab.value === tabs[tabs.length - 1].value
                                ? 'rounded-r-lg'
                                : ''
                        }`}
                        onClick={() => onTabChange(tab.value)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
        </div>
    )
}

// Add these imports at the top of the file
import { FaBook, FaGlobeAmericas } from 'react-icons/fa'
import { AppStoreButton } from '../components/AppStoreButton'
import { useEffect } from 'react'
// import appScreenshot from '../assets/app-screenshot.png' // Add this import

// Add this new component at the end of the file
export function Home() {
    useEffect(() => {
        document.title = 'Hungry Worm - Mapping Books to the Real World'
    }, [])
    return (
        <div className="h-screen flex relative overflow-hidden">
            {/* Left Column */}
            <div className="w-1/2  flex flex-col justify-center items-center p-12 z-[2]">
                <header className="text-center mb-12">
                    <h1 className="text-5xl font-bold mb-4">Hungry Worm</h1>
                    <p className="text-xl text-gray-600">
                        Mapping books to the real world
                    </p>
                </header>

                <div className="space-y-8 max-w-md">
                    <FeatureItem
                        icon={<FaBook className="text-2xl text-blue-500" />}
                        title="Discover Places"
                        description="Find mentions of places, persons and things in books."
                    />
                    <FeatureItem
                        icon={
                            <FaGlobeAmericas className="text-2xl text-green-500" />
                        }
                        title="Explore the World"
                        description="See what your favorite authors had to say about the city you are in."
                    />
                </div>
                <AppStoreButton />
            </div>

            {/* Right Column */}
            <div className="w-1/2 bg-[antiquewhite] flex items-center justify-center p-12 z-1">
                <div className="relative w-full max-w-md">
                    {/* <img
                        src={appScreenshot}
                        alt="Hungry Worm App Screenshot"
                        className="rounded-lg shadow-2xl"
                    /> */}
                </div>
            </div>

            {/* Tilted Divider */}
            <div className="absolute inset-0 z-2">
                <div className="absolute top-0 bottom-0 left-1/2 w-[300px] bg-[antiquewhite] transform skew-x-[6deg] translate-x-[-50px]"></div>
            </div>
        </div>
    )
}

function FeatureItem({
    icon,
    title,
    description,
}: {
    icon: React.ReactNode
    title: string
    description: string
}) {
    return (
        <div className="flex items-start">
            <div className="flex-shrink-0 mr-4">{icon}</div>
            <div>
                <h3 className="text-lg font-semibold mb-1">{title}</h3>
                <p className="text-sm text-gray-600">{description}</p>
            </div>
        </div>
    )
}

import { Navbar } from './components/Navbar'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { BookList } from './pages/BookList'
import { Editor } from './pages/editor/Editor'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Login } from './pages/auth/Login'
import { Signup } from './pages/auth/Signup'
import { AuthProvider, useAuthContext } from './context/AuthContext'
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo'
import { Home } from './pages/home'
import { BookInfo } from './pages/BookInfo'

function App() {
    return (
        <div className="App h-screen flex flex-col">
            <ApolloProvider client={client}>
                <AuthProvider>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route element={<PrivateRoutes />}>
                            <Route
                                path="/editor/books"
                                element={<BookList />}
                            />
                            <Route
                                path="/editor/:bookId"
                                element={<Editor />}
                            />
                        </Route>
                        <Route element={<PublicRoutes />}>
                            <Route
                                path="/auth/login"
                                element={<Login />}
                            ></Route>
                            <Route
                                path="/auth/signup"
                                element={<Signup />}
                            ></Route>
                        </Route>
                        <Route path="/book/:bookId" element={<BookInfo />} />
                    </Routes>
                    <ToastContainer
                        hideProgressBar={true}
                        draggable={false}
                        autoClose={3000}
                    />
                </AuthProvider>
            </ApolloProvider>
        </div>
    )
}

function PrivateRoutes() {
    const { user } = useAuthContext()
    return user ? <Outlet /> : <Navigate to="/auth/login" />
}

function PublicRoutes() {
    const { user } = useAuthContext()
    return user ? <Navigate to="/editor/books" /> : <Outlet />
}

export default App

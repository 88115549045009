import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BooksWithAuthors } from 'hungrywormsdk'
import { useQuery } from '@apollo/client'
import { GET_BOOKS, GET_AUTHORS } from '../utils/queries'
import { CreateBookModal } from '../components/CreateBookModal'
import { TabSelector } from '../components/TabSelector'
import { AuthorsList } from './bookList/AuthorList'

import { Spinner } from '../components/Spinner'

export function BookList() {
    const [activeTab, setActiveTab] = useState<'books' | 'authors'>('books')
    const [books, setBooks] = useState<BooksWithAuthors[]>([])

    const { loading: booksLoading, data: booksData } = useQuery(GET_BOOKS)

    const {
        loading: authorsLoading,
        error: authorsError,
        data: authorsData,
    } = useQuery(GET_AUTHORS)

    // const authorsLoading = false
    // const authorsError = null
    // const [authorsData, setAuthorsData] = useState<Author[]>([
    //     {
    //         id: 1,
    //         name: 'Author 1',
    //         createdAt: '2023-06-01',
    //         updatedAt: '2023-06-15',
    //     },
    //     {
    //         id: 2,
    //         name: 'Author 2',
    //         createdAt: '2023-06-02',
    //         updatedAt: '2023-06-16',
    //     },
    //     {
    //         id: 3,
    //         name: 'Author 3',
    //         createdAt: '2023-06-03',
    //         updatedAt: '2023-06-17',
    //     },
    // ])
    // TODO: Uncomment and use actual query when ready
    // const {
    //     loading: authorsLoading,
    //     error: authorsError,
    //     data: authorsData,
    // } = useQuery(GET_AUTHORS)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const tabs = [
        { value: 'books', label: 'Books' },
        { value: 'authors', label: 'Authors' },
    ]

    useEffect(() => {
        if (booksData) setBooks(booksData.books || [])
        // if (authorsData) setAuthors(authorsData.authors || [])
    }, [booksData, authorsData])

    return (
        <div className="p-4">
            <TabSelector
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={(value) =>
                    setActiveTab(value as 'books' | 'authors')
                }
            />
            <div className="flex flex-row flex-wrap">
                {activeTab === 'books' && (
                    <>
                        {booksLoading ? (
                            <div className="w-full h-[calc(100vh-200px)] flex items-center justify-center">
                                <Spinner message="Loading books..." />
                            </div>
                        ) : (
                            <>
                                <div
                                    className="m-4 flex flex-col items-center p-2 cursor-pointer select-none border-2 border-transparent hover:border-black"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    <div className="w-32 h-48 mb-2 flex items-center justify-center bg-gray-200">
                                        <span className="text-4xl">+</span>
                                    </div>
                                    <h4 className="font-medium">
                                        Create New Book
                                    </h4>
                                </div>
                                {books.map((book) => (
                                    <BookItem bookData={book} key={book.id} />
                                ))}
                            </>
                        )}
                    </>
                )}
                {activeTab === 'authors' && (
                    <AuthorsList
                        authors={authorsData?.authors || []}
                        loading={authorsLoading}
                        error={authorsError}
                    />
                )}
            </div>
            <CreateBookModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </div>
    )
}

export function BookItem({ bookData }: { bookData: any }) {
    const navigate = useNavigate()
    function onSelect() {
        navigate(`/editor/${bookData.id}`)
    }

    const imgUrl = bookData.img_url || undefined

    return (
        <div
            className="m-4 flex flex-col items-center p-2 cursor-pointer select-none border-2 border-transparent hover:border-black"
            onClick={onSelect}
        >
            <img
                src={imgUrl}
                alt={`${bookData.title} cover`}
                className="w-32 mb-2"
            />
            <h4 className="font-medium">{bookData.title}</h4>
            <p>{bookData.authors[0].name}</p>
        </div>
    )
}

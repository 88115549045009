import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBV6BFADt0bTtBh-5gxuZNSoGOTkQ4wY9Y',
    authDomain: 'hungry-worm-c75ba.firebaseapp.com',
    projectId: 'hungry-worm-c75ba',
    storageBucket: 'hungry-worm-c75ba.appspot.com',
    messagingSenderId: '267735004844',
    appId: '1:267735004844:web:89153927cd0128031c9109',
    measurementId: 'G-BCN4E7PQ11',
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
auth.useDeviceLanguage()

export { firebaseApp, auth }

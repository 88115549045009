import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { EditorContext } from '../../context/EditorContext'
import { EntityRow } from './EntityRow'
import { List } from 'react-virtualized'

export function EntityList() {
    const { entities, entityGroup } = useContext(EditorContext)
    const listContRef = useRef<HTMLDivElement>(null)
    const [search, setSearch] = useState('')

    const useVirtualList = useMemo(() => {
        return entityGroup === undefined || entityGroup === -1
    }, [entityGroup])

    const listItems = useMemo(() => {
        // All
        if (entityGroup === undefined) return entities

        // Unassigned
        if (entityGroup === -1) {
            return entities.filter((ent) => {
                return ent.bookEntityGroupId === null
            })
        }

        // Selected group
        const vals = entities.filter((ent) => {
            return ent.bookEntityGroupId === entityGroup
        })

        // Order by priority
        return vals.sort((a, b) => {
            return a.priority - b.priority
        })
    }, [entities, entityGroup])

    const searchItems = useMemo(() => {
        if (!search) return listItems

        return listItems.filter((item) => {
            const s = search.toLocaleLowerCase()
            return (
                item.word.toLowerCase().includes(s) ||
                item.quote.toLowerCase().includes(s)
            )
        })
    }, [listItems, search])

    useEffect(() => {
        if (entityGroup && entityGroup >= 0) setSearch('')
    }, [entityGroup])

    const [w, h] = useMemo(() => {
        if (!listContRef.current) return [0, 0]

        return [
            listContRef.current.clientWidth,
            listContRef.current.clientHeight,
        ]
        // eslint-disable-next-line
    }, [listContRef, entityGroup, listItems])

    function renderRow({
        index,
        key,
        style,
    }: {
        index: number
        key: any
        style: any
    }) {
        const entity = searchItems[index]
        return <EntityRow entity={entity} key={key} style={style}></EntityRow>
    }

    return (
        <div className="flex flex-col h-full w-full" ref={listContRef}>
            <input
                type="text"
                value={search}
                onChange={(ev) => {
                    setSearch(ev.target.value)
                }}
                placeholder="Search"
                className="p-1 px-3 bg-slate-100 text-sm my-2 w-1/3 mx-auto border"
            ></input>
            {useVirtualList ? (
                <>
                    <List
                        width={w}
                        height={h}
                        rowRenderer={renderRow}
                        rowCount={searchItems.length}
                        rowHeight={220}
                        className="h-full w-full"
                    />
                </>
            ) : (
                <div className="flex-grow overflow-y-scroll">
                    {searchItems.map((ent) => {
                        return <EntityRow entity={ent} key={ent.id}></EntityRow>
                    })}
                </div>
            )}
        </div>
    )
}

const API_KEY = process.env.REACT_APP_PLACES_API_KEY

// export async function searchPlace(text: string, mapEl: HTMLElement) {
//     const lib = await google.maps.importLibrary('places')
//     const {  } = lib as google.maps.PlacesLibrary

//     const map = new google.maps.Map(mapEl, {center: sydney, zoom: 15});

//     var service = new google.maps.places.PlacesService();

// }

export async function getPlaceDetails(id: string) {
    const lib = await google.maps.importLibrary('places')
    const { Place } = lib as google.maps.PlacesLibrary

    // Use place ID to create a new Place instance.
    const place = new Place({
        id,
        requestedLanguage: 'en', // optional
    })

    // Call fetchFields, passing the desired data fields.
    await place.fetchFields({
        fields: [
            'displayName',
            'formattedAddress',
            'location',
            'photos',
            'rating',
            'reviews',
            'websiteURI',
            'editorialSummary',
        ],
    })

    return place
}

export function getPhotoUrl(ref: string) {
    if (!API_KEY) {
        throw new Error('Api key not set.')
    }

    const params = new URLSearchParams({
        photo_reference: ref,
        key: API_KEY,
        maxwidth: '400',
    })

    const url = `https://maps.googleapis.com/maps/api/place/photo?${params.toString()}`
    return url
}

import { useEffect, useRef, useState } from 'react'

let searchTimeout: NodeJS.Timeout | undefined

export function PlaceIdSearch({
    onSelect,
}: {
    onSelect: (val: string) => any
}) {
    const mapRef = useRef<HTMLDivElement>(null)

    const [results, setResult] = useState<google.maps.places.PlaceResult[]>([])
    const [query, setQuery] = useState('')

    const [mapService, setMapService] = useState<
        google.maps.places.PlacesService | undefined
    >(undefined)

    useEffect(() => {
        if (!mapRef.current) return

        google.maps.importLibrary('places').then((lib) => {
            const { PlacesService } = lib as google.maps.PlacesLibrary
            // init place service
            const s = new PlacesService(mapRef.current!)
            setMapService(s)
        })
    }, [])
    // const mapService = useMemo(() => {}, [])

    useEffect(() => {
        if (!query) {
            setResult([])
            return
        }

        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        searchTimeout = setTimeout(() => {
            search(query)
        }, 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    function search(val: string) {
        if (!mapService) return

        const r: google.maps.places.FindPlaceFromQueryRequest = {
            fields: ['place_id', 'name', 'formatted_address'],
            query: val,
        }

        mapService.findPlaceFromQuery(r, (res) => {
            if (!res) return
            setResult(res)
        })
    }
    return (
        <div>
            <div ref={mapRef} className="w-0 h-0 bg-slate-200"></div>
            <input
                type="text"
                value={query}
                onChange={(ev) => {
                    setQuery(ev.target.value)
                }}
                className="w-full p-1"
            ></input>
            {!!results.length && (
                <div className="bg-white border border-slate-500">
                    {results.map((r) => {
                        return (
                            <div className="border-b-2">
                                <p
                                    onClick={() => {
                                        onSelect(r.place_id!)
                                    }}
                                    className="cursor-pointer hover:bg-blue-300"
                                >
                                    {r.name} - {r.formatted_address}
                                </p>
                                <a
                                    href={`https://www.google.com/maps/place/?q=place_id:${r.place_id}`}
                                    target="_blank"
                                    className="text-blue-400"
                                    rel="noreferrer"
                                >
                                    Show on maps
                                </a>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

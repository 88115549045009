import { useMemo } from 'react'

/**
 *
 * @param param0 Highlights a cartain section of the given text
 */
export function TextHighlight({
    text,
    start,
    end,
    textStyle,
}: {
    text: string
    start: number
    end: number
    textStyle?: React.ComponentProps<'span'>['className']
}) {
    const slices = useMemo(
        () => (
            <>
                <span key={0}>{text.slice(0, start)}</span>
                <span className={textStyle || 'font-bold'} key={1}>
                    {text.slice(start, end)}
                </span>
                <span key={2}>{text.slice(end)}</span>
            </>
        ),
        [text, start, end, textStyle]
    )

    return <>{slices}</>
}

import { useParams } from 'react-router-dom'
import { EditorProvider } from '../../context/EditorContext'
import { EntityList } from './EntityList'
import { EntityGroups } from './EntityGroups'

// import { NerList } from './NerList'
// import { PageViewer } from './PageViewer'

export function Editor() {
    let { bookId } = useParams()

    return (
        <EditorProvider bookId={parseInt(bookId!)}>
            <div
                className="flex flex-row  w-screen"
                style={{ height: 'calc(100vh - 48px)' }}
            >
                <div className="flex-shrink-0">
                    <EntityGroups></EntityGroups>
                </div>

                <div className="flex-grow">
                    <EntityList></EntityList>
                </div>
            </div>
        </EditorProvider>
    )
}

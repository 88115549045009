import { useContext, useMemo, useState } from 'react'
import { EditorContext } from '../../context/EditorContext'
import { EntityGroupDetails } from './EntityGroupDetails'
import { GroupStatus } from './GroupStatus'
import { BookEntityGroup, BookEntityGroupType } from '../../gql/graphql'

export function EntityGroups() {
    const { groups, entityGroup } = useContext(EditorContext)
    const [searchQuery, setSearchQuery] = useState('')
    const [filterValue, setFilterValue] = useState<BookEntityGroupType | null>(
        null
    )

    // TODO: This is sorting in place, maybe we need to sort on a new array
    const sorted = useMemo(() => {
        return [...groups].sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
    }, [groups])

    const published = useMemo(() => {
        return sorted.filter(
            (g) =>
                g.published &&
                g.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (!filterValue || g.type === filterValue)
        )
    }, [sorted, searchQuery, filterValue])

    const notPublished = useMemo(() => {
        return sorted.filter(
            (g) =>
                !g.published &&
                g.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (!filterValue || g.type === filterValue)
        )
    }, [sorted, searchQuery, filterValue])

    return (
        <div className="flex flex-row bg-slate-100 h-full">
            <div
                className="flex flex-col border-r"
                style={{
                    width: '20vw',
                    minWidth: '200px',
                    maxWidth: '300px',
                }}
            >
                <EntityGroupButton
                    name="All"
                    id={undefined}
                ></EntityGroupButton>
                <EntityGroupButton
                    name="Unassigned"
                    id={-1}
                ></EntityGroupButton>
                <div className="border mt-2"></div>
                <div className="p-2 pb-0">
                    <input
                        type="text"
                        placeholder="Search groups..."
                        className="w-full p-2 border rounded-md bg-white"
                        onChange={(e) => {
                            setSearchQuery(e.target.value)
                        }}
                    />
                </div>
                <div className="p-2">
                    <select
                        className="w-full p-2 border rounded-md bg-white"
                        onChange={(e) =>
                            setFilterValue(
                                e.target.value as BookEntityGroupType
                            )
                        }
                    >
                        <option value="">Filter Group Type</option>
                        {Object.entries(BookEntityGroupType).map(
                            ([key, value]) => (
                                <option key={key} value={value}>
                                    {key}
                                </option>
                            )
                        )}
                    </select>
                </div>
                <div className="flex-grow overflow-y-scroll no-scrollbar">
                    <p className="text-center text-sm w-full bg-green-200 sticky top-0">
                        Published
                    </p>
                    {published.map((group) => {
                        return (
                            <EntityGroupButton
                                name={group.name}
                                id={group.id}
                                key={group.id}
                                group={group}
                            ></EntityGroupButton>
                        )
                    })}
                    <p className="text-center text-sm w-full bg-red-200 sticky top-0">
                        Not Published
                    </p>
                    {notPublished.map((group) => {
                        return (
                            <EntityGroupButton
                                name={group.name}
                                id={group.id}
                                key={group.id}
                                group={group}
                            ></EntityGroupButton>
                        )
                    })}
                </div>
            </div>
            {entityGroup !== undefined && (
                <EntityGroupDetails></EntityGroupDetails>
            )}
        </div>
    )
}

export function EntityGroupButton({
    name,
    id,
    group,
}: {
    name: string
    id: number | undefined
    group?: BookEntityGroup
}) {
    const { setEntityGroup, entityGroup, entities } = useContext(EditorContext)

    const count = useMemo(() => {
        // All filter
        if (id === undefined) return entities.length
        // Unassigned filter
        if (id === -1) {
            return entities.filter((ent) => ent.bookEntityGroupId === null)
                .length
        }
        return entities.filter((ent) => ent.bookEntityGroupId === id).length
    }, [entities, id])

    return (
        <div
            className="p-2 data-[active=true]:bg-yellow-50 cursor-pointer select-none hover:bg-slate-200 flex flex-row items-center justify-between"
            data-active={entityGroup === id}
            onClick={() => {
                setEntityGroup(id)
            }}
        >
            <div className="flex items-center">
                {group && <GroupStatus group={group}></GroupStatus>}
                {name}
            </div>
            <span className="font-light text-xs">({count})</span>
        </div>
    )
}

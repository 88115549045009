import React from 'react'

interface ButtonProps {
    size?: 'sm' | 'md' | 'lg' | 'xl'
    children: React.ReactNode
    type?: 'outline' | 'solid' | 'text'
    grow?: true | false
    disabled?: boolean
    onClick?: () => void
    style?: React.CSSProperties
}

const COLOR_PRIMARY = '#1e293b'
const COLOR_WHITE = '#FFFFFF'

export function ButtonPrimary({
    size = 'md',
    type = 'outline',
    children,
    grow = true,
    onClick,
    disabled = false,
    style,
}: ButtonProps) {
    function getSizeStyle() {
        switch (size) {
            case 'sm':
                return {
                    padding: '0.5rem',
                    fontSize: '0.5rem',
                }
            case 'md':
                return {
                    padding: '0.6rem',
                    fontSize: '0.7rem',
                }
            case 'lg':
                return {
                    padding: '0.8rem',
                    fontSize: '0.8rem',
                }
            case 'xl':
                return {
                    padding: '1rem',
                    fontSize: '1rem',
                }
        }
    }

    function getTypeStyle() {
        switch (type) {
            case 'outline':
                return {
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: '#b0bbce',
                }
            case 'solid':
                return {
                    border: 'none',
                    backgroundColor: COLOR_PRIMARY,
                    color: COLOR_WHITE,
                }
            case 'text':
                return {
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: COLOR_PRIMARY,
                }
        }
    }

    function getTailwindCSS() {
        switch (type) {
            case 'outline':
                return 'hover:bg-slate-200'
            case 'text':
                return 'hover:bg-slate-200'
            default:
                return 'hover:opacity-70'
        }
    }

    return (
        <button
            className={'disabled:opacity-25 cursor-pointer ' + getTailwindCSS()}
            style={{
                ...style,
                borderRadius: '2px',
                ...getSizeStyle(),
                ...getTypeStyle(),
                width: grow ? '100%' : 'fit-content',
            }}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth } from '../firebase'

const provider = new GoogleAuthProvider()

export function signInWithGoogle(success?: any, onError?: any) {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result)

            if (!credential) return

            // const token = credential.accessToken
            // The signed-in user info.
            // const user = result.user
            // IdP data available using getAdditionalUserInfo(result)
            // ...
            success()
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // The email of the user's account used.
            const email = error.customData.email
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error)
            // ...
            onError(errorCode, errorMessage, email, credential)
        })
}

import {
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { EditorContext } from '../../context/EditorContext'
import { FormLabel } from '../../components/FormLabel'
import { TextHighlight } from '../../components/TextHighlight'
import { toast } from 'react-toastify'
import { ButtonPrimary } from '../../components/themed'
import { BookEntity } from '../../gql/graphql'

import { client } from '../../apollo'
import {
    CREATE_ENTITY_GROUP,
    DELETE_ENTITY,
    UPDATE_ENTITY,
} from '../../utils/queries'

export function EntityRow({
    entity,
    style = {},
}: {
    entity: BookEntity
    style?: React.CSSProperties
}) {
    const {
        groups,
        updateBookEntity,
        fetchEntityGroups,
        deleteBookEntity,
        fetchBookEntities,
    } = useContext(EditorContext)

    const [isHidden, setIsHidden] = useState(false)

    useEffect(() => {
        setLocalState({ ...entity })
    }, [entity])

    const [localState, setLocalState] = useState<BookEntity>({ ...entity })

    const isChanged = useMemo(() => {
        return JSON.stringify(entity) !== JSON.stringify(localState)
    }, [entity, localState])

    // If the entity doesnt have a group but there is a matching group, select it
    useEffect(() => {
        const groupMatch = groups.find((g) => g.name === localState.word)
        if (!groupMatch) return
        if (localState.bookEntityGroupId !== groupMatch.id) {
            setLocalState({
                ...localState,
                bookEntityGroupId: groupMatch.id,
            })
        }
    }, [groups, localState])

    async function save() {
        const res = (
            await client.mutate({
                mutation: UPDATE_ENTITY,
                variables: {
                    entity: {
                        entityId: entity.id,
                        word: localState.word,
                        quoteStart: localState.quoteStart,
                        quoteEnd: localState.quoteEnd,
                        quote: localState.quote,
                        published: localState.published,
                        priority: localState.priority,
                    },
                },
            })
        ).data!.updateEntity

        updateBookEntity(entity.id, res)

        toast(`Updated entity: ${res.word}`, {
            type: 'success',
        })
    }

    async function deleteEntity() {
        try {
            const res = await client.mutate({
                mutation: DELETE_ENTITY,
                variables: {
                    id: entity.id,
                },
            })

            if (res.data?.deleteEntity) {
                deleteBookEntity(entity.id)
                toast(`Deleted entity: ${entity.word}`, {
                    type: 'success',
                })
            }
        } catch {
            toast(`Failed to delete entity: ${entity.word}`, {
                type: 'error',
            })
        }
    }

    async function createNewEntityGroup() {
        try {
            const res = await client.mutate({
                mutation: CREATE_ENTITY_GROUP,
                variables: {
                    bookId: entity.bookId,
                    name: entity.word,
                },
            })

            if (res.errors || !res.data?.createEntityGroup) {
                console.log(res.errors)
                toast(`Failed to create group: ${entity.word}`, {
                    type: 'error',
                })
                return
            }

            // Load entity groups to context state
            await fetchEntityGroups()

            setLocalState({
                ...localState,
                bookEntityGroupId: res.data.createEntityGroup.id,
            })
            await fetchBookEntities()

            toast(`Created and populated group: ${entity.word}`, {
                type: 'success',
            })
        } catch {
            toast(`Failed to create entity group: ${entity.word}`, {
                type: 'error',
            })
        }
    }

    function onGroupChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const val = event.target.value
        const parsed = val === '' ? null : parseInt(event.target.value)

        setLocalState({
            ...localState,
            bookEntityGroupId: parsed,
        })
    }

    function onQuoteStartChange(event: React.ChangeEvent<HTMLInputElement>) {
        const val = parseInt(event.target.value)
        if (val >= localState.quoteEnd) return
        setLocalState({
            ...localState,
            quoteStart: val,
        })
    }

    function onQuoteEndChange(event: React.ChangeEvent<HTMLInputElement>) {
        const val = parseInt(event.target.value)
        if (val <= localState.quoteStart) return
        setLocalState({
            ...localState,
            quoteEnd: val,
        })
    }

    function onWordChange(event: React.ChangeEvent<HTMLInputElement>) {
        const val = event.target.value

        setLocalState({
            ...localState,
            word: val,
        })
    }

    return (
        <div
            className="grid grid-cols-12 border-b p-3 data-[hidden='true']:opacity-10 border-l-2 data-[status='changed']:border-l-yellow-400 data-[status='group']:border-l-green-600 data-[status='unset']:border-l-red-400"
            style={style}
            data-hidden={isHidden}
            data-status={
                isChanged
                    ? 'changed'
                    : entity.bookEntityGroupId != null
                      ? 'group'
                      : 'unset'
            }
            key={entity.id}
        >
            <div className="col-span-2 flex flex-col pr-2">
                <Section>
                    <FormLabel>Name</FormLabel>
                    <div className="flex flex-col">
                        <input
                            type="text"
                            value={localState.word}
                            className="font-bold text-lg"
                            onChange={onWordChange}
                        ></input>

                        <div className="flex flex-row items-center">
                            <span className="text-xs text-red-500 mr-2">
                                {isChanged && 'changed'}
                            </span>
                            {isChanged && (
                                <button
                                    onClick={() => setLocalState({ ...entity })}
                                    className="text-xs text-gray-500 hover:text-gray-700"
                                    title="Clear changes"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 inline-block"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                        />
                                    </svg>
                                </button>
                            )}
                        </div>
                    </div>
                </Section>
            </div>

            <div className="col-span-2">
                <Section>
                    <FormLabel>
                        Entity Group
                        {localState.bookEntityGroupId == null && (
                            <button
                                onClick={createNewEntityGroup}
                                className="float-right"
                            >
                                +
                            </button>
                        )}
                    </FormLabel>
                    <select
                        className="border"
                        name="groups"
                        id="groups"
                        value={
                            localState.bookEntityGroupId === null
                                ? ''
                                : localState.bookEntityGroupId
                        }
                        onChange={onGroupChange}
                    >
                        <option value={''}>-</option>
                        {groups.map((g) => {
                            return (
                                <option value={g.id} key={g.id}>
                                    {g.name}
                                </option>
                            )
                        })}
                    </select>
                </Section>
                <Section>
                    <FormLabel>Quote Start & End</FormLabel>
                    <div className="flex flex-row">
                        <input
                            type="number"
                            className="border box-border w-1/2 mr-1"
                            value={localState.quoteStart}
                            onChange={onQuoteStartChange}
                        ></input>
                        <input
                            className="border box-border w-1/2"
                            type="number"
                            value={localState.quoteEnd}
                            onChange={onQuoteEndChange}
                        ></input>
                    </div>
                </Section>
                <Section>
                    <FormLabel>Priority</FormLabel>
                    <div className="flex flex-row">
                        <input
                            type="number"
                            className="border box-border w-1/2 mr-1"
                            value={localState.priority}
                            min={0}
                            onChange={(ev) => {
                                setLocalState({
                                    ...localState,
                                    priority: parseInt(ev.target.value),
                                })
                            }}
                        ></input>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel>Published</FormLabel>
                        <input
                            className="ml-1"
                            type="checkbox"
                            name="Published"
                            checked={localState.published}
                            onChange={(ev) => {
                                setLocalState({
                                    ...localState,
                                    published: ev.target.checked,
                                })
                            }}
                        ></input>
                    </div>
                </Section>
            </div>

            <div className="col-span-2">
                <Section>
                    <ButtonPrimary
                        type="outline"
                        size="md"
                        onClick={() => {
                            setIsHidden(!isHidden)
                        }}
                    >
                        {isHidden ? 'Show' : 'Hide'}
                    </ButtonPrimary>
                    <ButtonPrimary
                        type="solid"
                        size="md"
                        onClick={save}
                        disabled={!isChanged}
                    >
                        Save
                    </ButtonPrimary>
                    <button onClick={deleteEntity} className="border my-2 mt-4">
                        Delete
                    </button>
                </Section>
            </div>
            <div className="col-span-6">
                <Section>
                    <p
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => {
                            const newQuote = e.currentTarget.textContent || ''
                            setLocalState({
                                ...localState,
                                quote: newQuote,
                            })
                        }}
                    >
                        <TextHighlight
                            text={localState.quote}
                            start={localState.quoteStart}
                            end={localState.quoteEnd}
                        />
                    </p>
                </Section>
            </div>
        </div>
    )
}

function Section({ children }: PropsWithChildren) {
    return <div className="flex flex-col p-2">{children}</div>
}

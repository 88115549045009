import {
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { EditorContext } from '../../context/EditorContext'
import { FormLabel } from '../../components/FormLabel'
// import { wormClient } from '../../wormClient'
import { toast } from 'react-toastify'
import { ButtonPrimary } from '../../components/themed'
// import { BookEntityGroupType } from 'hungrywormsdk'
// import { getPlaceDetails } from '../../utils/placesApi'
import { GooglePlaceDetails } from './GooglePlaceDetails'
import { BookEntityGroupType } from '../../gql/graphql'

import { client } from '../../apollo'
import { UPDATE_ENTITY_GROUP } from '../../utils/queries'
import { PlaceIdSearch } from '../../components/PlaceIdSearch'

export function EntityGroupDetails() {
    const { entityGroup, groups, updateBookEntityGroup } =
        useContext(EditorContext)

    const group = useMemo(() => {
        return groups.find((g) => g.id === entityGroup)
    }, [groups, entityGroup])

    const [localState, setLocalState] = useState(
        group ? { ...group } : undefined
    )

    useEffect(() => {
        if (!group) return
        setLocalState({ ...group })
    }, [group])

    const isChanged = useMemo(() => {
        return JSON.stringify(group) !== JSON.stringify(localState)
    }, [group, localState])

    if (
        entityGroup === undefined ||
        group === undefined ||
        localState === undefined
    )
        return <div></div>

    async function saveEntityGroup() {
        if (!localState || !group) return

        const res = await client.mutate({
            mutation: UPDATE_ENTITY_GROUP,
            variables: {
                entityGroup: {
                    groupId: group.id,
                    name: localState.name,
                    type: localState.type,
                    googleMapId: localState.googleMapId,
                    wikiUrl: localState.wikiUrl,
                    published: localState.published,
                },
            },
        })

        if (!res.data) {
            toast(`Failed to update group: ${localState.name}`, {
                type: 'success',
            })
            return
        }

        updateBookEntityGroup(
            res.data.updateEntityGroup.id,
            res.data.updateEntityGroup
        )

        toast(`Updated group: ${res.data.updateEntityGroup.name}`, {
            type: 'success',
        })
    }

    async function deleteEntityGroup() {
        if (!group || entityGroup === undefined) return

        const isConfirm = window.confirm('You sure bro?')

        if (!isConfirm) return
    }

    return (
        <div className="p-5 w-80">
            <textarea
                className="text-xl font-bold w-full bg-transparent resize-none h-fit"
                value={localState.name}
                onInput={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setLocalState({
                        ...localState,
                        name: ev.target.value,
                    })
                }}
            ></textarea>

            <Section>
                <FormLabel>Type</FormLabel>
                <select
                    value={localState.type === null ? '' : localState.type}
                    className="p-2"
                    onChange={(ev) => {
                        const val = ev.target.value
                        const parsed =
                            val === '' ? null : (val as BookEntityGroupType)
                        setLocalState({
                            ...localState,
                            type: parsed,
                        })
                    }}
                >
                    <option value={''} label="-"></option>
                    {Object.values(BookEntityGroupType).map((key) => {
                        return (
                            <option value={key} label={key} key={key}></option>
                        )
                    })}
                </select>
            </Section>
            <Section>
                <FormLabel>Google Maps ID</FormLabel>
                <input
                    type="text"
                    value={localState.googleMapId || ''}
                    className="p-2"
                    onInput={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        setLocalState({
                            ...localState,
                            googleMapId: ev.target.value || null,
                        })
                    }}
                ></input>
                <FormLabel>Search Place ID</FormLabel>
                <PlaceIdSearch
                    onSelect={(val) => {
                        setLocalState({
                            ...localState,
                            googleMapId: val,
                        })
                    }}
                />
            </Section>
            <Section>
                <FormLabel>Wikipedia Url</FormLabel>
                <input
                    type="text"
                    value={localState.wikiUrl || ''}
                    className="p-2"
                    onInput={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        setLocalState({
                            ...localState,
                            wikiUrl: ev.target.value || null,
                        })
                    }}
                ></input>
            </Section>
            <Section>
                <div className="flex flex-row">
                    <FormLabel>Published</FormLabel>
                    <input
                        type="checkbox"
                        className="ml-1"
                        value="published"
                        checked={localState.published}
                        onChange={(ev) => {
                            setLocalState({
                                ...localState,
                                published: ev.target.checked,
                            })
                        }}
                    ></input>
                </div>
            </Section>
            <Section>
                <ButtonPrimary
                    type="solid"
                    disabled={!isChanged}
                    style={{ marginBottom: '1rem' }}
                    onClick={saveEntityGroup}
                >
                    SAVE
                </ButtonPrimary>
                <ButtonPrimary type="outline" onClick={deleteEntityGroup}>
                    Delete
                </ButtonPrimary>
            </Section>

            {group.googleMapId && (
                <Section>
                    <GooglePlaceDetails
                        placeId={group.googleMapId}
                    ></GooglePlaceDetails>
                </Section>
            )}
        </div>
    )
}

function Section({ children }: PropsWithChildren) {
    return <div className="flex flex-col my-3">{children}</div>
}

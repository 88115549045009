import React from 'react'

interface SpinnerProps {
    message?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ message = 'Loading...' }) => {
    return (
        <div className="flex items-center justify-center w-full h-48">
            <div className="flex flex-col items-center">
                <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin mb-4"></div>
                <p>{message}</p>
            </div>
        </div>
    )
}

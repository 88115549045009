import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppStoreButton } from '../components/AppStoreButton'
import { GET_ENTITY_GROUPS_GOOGLE_ID } from '../utils/queries'
import { client } from '../apollo'

export async function getBookById(
    id: string,
    config?: {
        projection?: 'full' | 'lite'
    }
): Promise<any> {
    const encoded = new URLSearchParams(config).toString()
    let url = `https://www.googleapis.com/books/v1/volumes/${id}`

    if (config) {
        url += `?${encoded}`
    }
    const res = await (await fetch(url)).json()

    return res
}

const palette = {
    bg: '#f4fcff',
    text: '#333333',
    accent: '#007bff',
}

export function BookInfo() {
    const { bookId } = useParams()
    const [bookFound, setBookFound] = useState<any>(null)
    const [book, setBook] = useState<any>()
    const [groups, setGroups] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        bookId &&
            getBookById(bookId).then(async (res) => {
                console.log(res)
                if (res.error) {
                    setBookFound(false)
                    setIsLoading(false)
                } else {
                    setBook(res)
                    const groups = await client.query({
                        query: GET_ENTITY_GROUPS_GOOGLE_ID,
                        variables: {
                            id: bookId,
                        },
                    })
                    setGroups(groups.data.googleBook?.entityGroups || [])
                    setIsLoading(false)
                }
            })
    }, [bookId])

    useEffect(() => {
        if (book) {
            document.title =
                (book.volumeInfo.title || 'Book Info') + ' | Hungry Worm'

            // Add Open Graph meta tags
            const metaDescription = `Explore ${book.volumeInfo.title} with Hungry Worm! Discover places, things and persons mentioned in the book.`

            const metaImage =
                book.volumeInfo.imageLinks?.large ||
                book.volumeInfo.imageLinks?.thumbnail ||
                ''

            const meta = document.createElement('meta')
            meta.setAttribute('property', 'og:title')
            meta.setAttribute('content', book.volumeInfo.title)
            document.getElementsByTagName('head')[0].appendChild(meta)

            const metaDesc = document.createElement('meta')
            metaDesc.setAttribute('property', 'og:description')
            metaDesc.setAttribute('content', metaDescription)
            document.getElementsByTagName('head')[0].appendChild(metaDesc)

            const metaImg = document.createElement('meta')
            metaImg.setAttribute('property', 'og:image')
            metaImg.setAttribute('content', metaImage)
            document.getElementsByTagName('head')[0].appendChild(metaImg)

            const metaType = document.createElement('meta')
            metaType.setAttribute('property', 'og:type')
            metaType.setAttribute('content', 'book')
            document.getElementsByTagName('head')[0].appendChild(metaType)
        }
    }, [book])

    if (bookFound === false) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen ">
                <div className="text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <h2 className="mt-2 text-lg font-medium text-gray-900">
                        Book Not Found
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Unable to find the book you requested.
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="h-auto md:h-full bg-[#faebd7]">
            {book ? (
                <div className="flex justify-center h-full">
                    <div className=" w-full flex gap-8 overflow-hidden flex-col md:flex-row">
                        {/* Left column */}
                        <div className="w-full md:w-1/2 flex flex-col items-center pt-[20vh] relative">
                            {/* <div className="w-[70vw] h-[70vw] bottom-[-35vw] left-[-15vw] bg-cyan-400 absolute rounded-full z-[-1]"></div> */}
                            <div className="max-w-[600px] flex flex-col items-center">
                                <img
                                    src={
                                        book.volumeInfo.imageLinks?.large ||
                                        book.volumeInfo.imageLinks?.thumbnail ||
                                        'placeholder-image-url'
                                    }
                                    alt={`Cover of ${book.volumeInfo.title}`}
                                    className="w-[220px] h-auto rounded-md shadow-sm mb-4"
                                />

                                {!isLoading && (
                                    <PlaceOfInterest
                                        count={groups.length || 0}
                                    ></PlaceOfInterest>
                                )}

                                <div className="mt-2 max-w-[350px]">
                                    <AppStoreButton />
                                </div>
                            </div>
                        </div>
                        {/* Right column */}
                        <div
                            className="w-full md:w-1/2 h-full  pt-5 md:pt-[20vh] md:pl-[90px] overflow-auto md:overflow-y-scroll pb-[5vh]"
                            style={{ backgroundColor: palette.bg + '5e' }}
                        >
                            <div className="max-w-[450px] m-auto md:m-0  p-5">
                                <h2 className="text-sm text-gray-600 font-bold">
                                    {book.volumeInfo.authors?.join(', ') ||
                                        'Unknown Author'}
                                </h2>
                                <h1 className="text-5xl font-bold mb-2">
                                    {book.volumeInfo.title}
                                </h1>

                                <div
                                    className="text-gray-700 text-sm mb-4"
                                    dangerouslySetInnerHTML={{
                                        __html: `<style>
                                        p {
                                            padding: 8px 0;
                                        }
                                    </style>${book.volumeInfo.description || 'No description available.'}`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                </>
            )}
        </div>
    )
}

function PlaceOfInterest({
    count,
    bookName,
}: {
    count?: number
    bookName?: string
}) {
    return (
        <div className="mt-4 max-w-[350px] flex flex-row items-center">
            {count ? (
                <>
                    <p className="text-6xl font-light">{count}</p>
                    <p
                        className="text-[3rem] ml-5 font-extralight"
                        style={{ lineHeight: '38px' }}
                    >
                        Places of Interest
                    </p>
                </>
            ) : (
                <p className="text-[2rem] ml-5 font-extralight">
                    Download the app and start exploring!
                </p>
            )}
        </div>
    )
}

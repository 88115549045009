import { useMemo } from 'react'
import { BookEntityGroup } from 'hungrywormsdk'

export function GroupStatus({ group }: { group: BookEntityGroup }) {
    const stat = useMemo(() => {
        if (group.googleMapId && group.type) {
            return 2
        } else if (!group.googleMapId && !group.type) {
            return 0
        }
        return 1
    }, [group])

    return (
        <div
            data-stat={stat}
            className="w-1.5 h-1.5 mr-1 rounded data-[stat='2']:bg-green-600 data-[stat='1']:bg-yellow-600 data-[stat='0']:bg-red-600"
        ></div>
    )
}

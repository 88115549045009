import { FormLabel } from '../../components/FormLabel'
import { ButtonPrimary } from '../../components/themed'
import { AuthBody } from './AuthBody'

export function Signup() {
    return (
        <AuthBody>
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <FormLabel>Email</FormLabel>
                    <input className="p-2" type="email"></input>
                </div>
                <div className="flex flex-col my-2">
                    <FormLabel>Password</FormLabel>
                    <input className="p-2" type="password"></input>
                </div>
                <ButtonPrimary type="solid">Signup</ButtonPrimary>
            </div>
            <div>
                <button>Sign in with Google</button>
            </div>
            <div>
                <a href="/auth/signup">Dont have an account?</a>
            </div>
        </AuthBody>
    )
}

import React from 'react'
import { Author } from '../../gql/graphql'

interface AuthorsListProps {
    authors: Partial<Author>[]
    loading: boolean
    error: any
}

export function AuthorsList({ authors, loading, error }: AuthorsListProps) {
    if (loading) return <p>Loading authors...</p>
    if (error) return <p>Error loading authors: {error.message}</p>

    return (
        <>
            <AddAuthorButton />
            {authors.map((author) => (
                <AuthorItem authorData={author} key={author.id} />
            ))}
        </>
    )
}

function AuthorItem({ authorData }: { authorData: Partial<Author> }) {
    return (
        <div className="m-4 flex flex-col items-center border-2 border-transparent hover:border-black p-2 rounded-md cursor-pointer select-none">
            <div className="w-32 h-48 mb-2 flex items-center justify-center bg-gray-200 rounded">
                <span className="text-2xl">{authorData.name?.charAt(0)}</span>
            </div>
            <h4 className="font-medium">{authorData.name}</h4>
            {/* <p>{authorData.books.length} books</p> */}
        </div>
    )
}

function AddAuthorButton() {
    return (
        <div className="m-4 flex flex-col items-center border-2 border-transparent hover:border-black p-2 rounded-md cursor-pointer select-none">
            <div className="w-32 h-48 mb-2 flex items-center justify-center bg-gray-200 rounded">
                <span className="text-4xl">+</span>
            </div>
            <h4 className="font-medium">Add New Author</h4>
        </div>
    )
}

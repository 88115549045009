import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'

import { auth } from '../firebase'
import { User } from 'firebase/auth'
import { Role } from '../PrismaClient'
import { clearAuthToken, setAuthToken, client } from '../apollo'
import { GET_USER_DETAIL } from '../utils/queries'

export interface AuthContextType {
    user: User | null
    role: Role | null
}

export const AuthContext = createContext<AuthContextType>({
    user: null,
    role: null,
})

// every 30 minutes
const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 30

export function AuthProvider({ children }: { children?: ReactNode }) {
    const [user, setUser] = useState<User | null>(null)
    const [role, setRole] = useState<Role | null>(null)

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            user && refreshIdToken(user)
        }, TOKEN_REFRESH_INTERVAL)

        return () => {
            clearInterval(refreshInterval)
        }
    }, [user])

    useEffect(() => {
        auth.onAuthStateChanged((newUser) => {
            clearAuthToken()
            setRole(null)

            setUser(newUser)

            if (newUser) {
                refreshIdToken(newUser).then(async (res) => {
                    const userInfo = (
                        await client.query({
                            query: GET_USER_DETAIL,
                        })
                    ).data.userDetails

                    if (userInfo) setRole(userInfo.role)
                })
            }
        })
    }, [])

    async function refreshIdToken(user: User) {
        const res = await user.getIdToken()
        setAuthToken(res)
        return res
    }

    return (
        <AuthContext.Provider value={{ user, role }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuthContext() {
    return useContext(AuthContext)
}

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

// const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     const token = localStorage.getItem('token')
//     // return the headers to the context so httpLink can read them
//     return {
//         headers: {
//             ...headers,
//             authorization: token ? `Bearer ${token}` : '',
//         },
//     }
// })

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
})

export function setAuthToken(token: string) {
    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // const token = localStorage.getItem('token')
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? token : '',
            },
        }
    })

    client.setLink(authLink.concat(httpLink))
}

export function clearAuthToken() {
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: '',
            },
        }
    })

    client.setLink(authLink.concat(httpLink))
}

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
})

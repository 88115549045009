import { Link, useLocation } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'
import { auth } from '../firebase'

export function Navbar() {
    const { user, role } = useAuthContext()
    const location = useLocation()

    function logout() {
        auth.signOut()
    }

    const bgTransparent =
        location.pathname === '/' ||
        location.pathname.substring(0, 6) === '/book/'

    const navbarClass = `h-10 flex flex-row items-center justify-between p-6 lg:px-8 w-full  top-0 z-10 ${
        bgTransparent
            ? 'bg-transparent text-gray-800 fixed'
            : 'bg-slate-800 text-white relative'
    }`

    return (
        <nav className={navbarClass}>
            <div>
                <Link to="/" className="text-sm font-semibold leading-6">
                    HungryWorm
                </Link>
            </div>
            <div>
                {role && ['ADMIN', 'MODERATOR'].includes(role) && (
                    <>
                        <Link
                            to="/editor/books"
                            className="text-sm font-semibold leading-6 p-2.5"
                        >
                            Edit Books
                        </Link>
                    </>
                )}
            </div>
            <div>
                {user ? (
                    <>
                        <span className="text-sm font-semibold leading-6 mr-4">
                            Role: {role || 'User'}
                        </span>
                        <button onClick={logout}>Logout</button>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </nav>
    )
}

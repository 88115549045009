/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  DateTimeTypeDefinition: { input: any; output: any; }
};

export type Author = {
  __typename?: 'Author';
  about?: Maybe<Scalars['String']['output']>;
  books?: Maybe<Array<Maybe<Book>>>;
  createdAt: Scalars['DateTimeTypeDefinition']['output'];
  id: Scalars['Int']['output'];
  img_url?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeTypeDefinition']['output'];
};

export type Book = {
  __typename?: 'Book';
  authors: Array<Author>;
  createdAt: Scalars['DateTimeTypeDefinition']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entities: Array<BookEntity>;
  entityGroups: Array<BookEntityGroup>;
  google_book_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeTypeDefinition']['output'];
};

export type BookEntity = {
  __typename?: 'BookEntity';
  book?: Maybe<Book>;
  bookEntityGroupId?: Maybe<Scalars['Int']['output']>;
  bookId: Scalars['Int']['output'];
  entityGroup?: Maybe<BookEntityGroup>;
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  quote: Scalars['String']['output'];
  quoteEnd: Scalars['Int']['output'];
  quoteStart: Scalars['Int']['output'];
  word: Scalars['String']['output'];
};

export type BookEntityGroup = {
  __typename?: 'BookEntityGroup';
  book?: Maybe<Book>;
  bookId: Scalars['Int']['output'];
  details?: Maybe<EntityGroupGoogleDetails>;
  entities?: Maybe<Array<BookEntity>>;
  googleMapId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  type?: Maybe<BookEntityGroupType>;
  wikiUrl?: Maybe<Scalars['String']['output']>;
};

export enum BookEntityGroupType {
  Bar = 'BAR',
  Cafe = 'CAFE',
  Club = 'CLUB',
  Drink = 'DRINK',
  Food = 'FOOD',
  Region = 'REGION',
  Restaurant = 'RESTAURANT'
}

export type CreateAuthorInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateBookInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  google_book_id?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type EntityGroupGoogleDetails = {
  __typename?: 'EntityGroupGoogleDetails';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  entityGroups: Array<BookEntityGroup>;
  googleImages: Array<Scalars['String']['output']>;
  googleurl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  tags: Array<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addGoogleDetails: EntityGroupGoogleDetails;
  createAuthor: Author;
  createBook: Book;
  createEntityGroup: BookEntityGroup;
  deleteEntity?: Maybe<BookEntity>;
  deleteEntityGroup?: Maybe<BookEntityGroup>;
  updateEntity: BookEntity;
  updateEntityGroup: BookEntityGroup;
};


export type MutationAddGoogleDetailsArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateAuthorArgs = {
  author: CreateAuthorInput;
};


export type MutationCreateBookArgs = {
  book: CreateBookInput;
};


export type MutationCreateEntityGroupArgs = {
  bookId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationDeleteEntityArgs = {
  entityId: Scalars['Int']['input'];
};


export type MutationDeleteEntityGroupArgs = {
  bookId: Scalars['Int']['input'];
  groupId: Scalars['Int']['input'];
};


export type MutationUpdateEntityArgs = {
  entity: UpdateEntityInput;
};


export type MutationUpdateEntityGroupArgs = {
  entityGroup: UpdateEntityGroupInput;
};

export type Query = {
  __typename?: 'Query';
  author?: Maybe<Author>;
  authors: Array<Author>;
  book?: Maybe<Book>;
  bookEntities: Array<BookEntity>;
  bookEntityGroup?: Maybe<BookEntityGroup>;
  bookEntityGroups: Array<BookEntityGroup>;
  bookEntityGroupsGoogleId: Array<BookEntityGroup>;
  books: Array<Book>;
  googleBook?: Maybe<Book>;
  groupDetails?: Maybe<EntityGroupGoogleDetails>;
  userDetails?: Maybe<UserDetail>;
};


export type QueryAuthorArgs = {
  authorId: Scalars['Int']['input'];
};


export type QueryBookArgs = {
  bookId: Scalars['Int']['input'];
};


export type QueryBookEntitiesArgs = {
  bookId: Scalars['Int']['input'];
};


export type QueryBookEntityGroupArgs = {
  groupId: Scalars['Int']['input'];
};


export type QueryBookEntityGroupsArgs = {
  bookId: Scalars['Int']['input'];
};


export type QueryBookEntityGroupsGoogleIdArgs = {
  googleId: Scalars['String']['input'];
};


export type QueryGoogleBookArgs = {
  googleBookId: Scalars['String']['input'];
};


export type QueryGroupDetailsArgs = {
  googlePlaceId: Scalars['String']['input'];
};

export type UpdateEntityGroupInput = {
  googleMapId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<BookEntityGroupType>;
  wikiUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEntityInput = {
  bookEntityGroupId?: InputMaybe<Scalars['Int']['input']>;
  entityId: Scalars['Int']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  quoteEnd?: InputMaybe<Scalars['Int']['input']>;
  quoteStart?: InputMaybe<Scalars['Int']['input']>;
  word?: InputMaybe<Scalars['String']['input']>;
};

export type UserDetail = {
  __typename?: 'UserDetail';
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  role: UserRole;
};

export enum UserRole {
  Admin = 'ADMIN',
  Moderator = 'MODERATOR',
  User = 'USER'
}

export type GetUserDetailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDetailQuery = { __typename?: 'Query', userDetails?: { __typename?: 'UserDetail', email?: string | null, emailVerified?: boolean | null, role: UserRole } | null };

export type UpdateEntityGroupMutationVariables = Exact<{
  entityGroup: UpdateEntityGroupInput;
}>;


export type UpdateEntityGroupMutation = { __typename?: 'Mutation', updateEntityGroup: { __typename?: 'BookEntityGroup', id: number, bookId: number, name: string, type?: BookEntityGroupType | null, googleMapId?: string | null, wikiUrl?: string | null, published: boolean } };

export type GetBooksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBooksQuery = { __typename?: 'Query', books: Array<{ __typename?: 'Book', id: number, title: string, description?: string | null, img_url?: string | null, authors: Array<{ __typename?: 'Author', name: string }> }> };

export type GetAuthorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthorsQuery = { __typename?: 'Query', authors: Array<{ __typename?: 'Author', id: number, name: string }> };

export type UpdateEntityMutationVariables = Exact<{
  entity: UpdateEntityInput;
}>;


export type UpdateEntityMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'BookEntity', id: number, bookId: number, word: string, quoteStart: number, quoteEnd: number, quote: string, bookEntityGroupId?: number | null, published: boolean, priority: number } };

export type DeleteEntityMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteEntityMutation = { __typename?: 'Mutation', deleteEntity?: { __typename?: 'BookEntity', word: string } | null };

export type CreateEntityGroupMutationVariables = Exact<{
  bookId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}>;


export type CreateEntityGroupMutation = { __typename?: 'Mutation', createEntityGroup: { __typename?: 'BookEntityGroup', id: number, name: string } };

export type GetPlaceDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetPlaceDetailsQuery = { __typename?: 'Query', groupDetails?: { __typename?: 'EntityGroupGoogleDetails', address?: string | null, country?: string | null, city?: string | null, googleImages: Array<string>, tags: Array<string>, description?: string | null, rating?: number | null, longitude?: number | null, latitude?: number | null, googleurl?: string | null, website?: string | null } | null };

export type CreateBookMutationVariables = Exact<{
  bookInput: CreateBookInput;
}>;


export type CreateBookMutation = { __typename?: 'Mutation', createBook: { __typename?: 'Book', title: string, description?: string | null, img_url?: string | null, google_book_id?: string | null } };

export type CreateAuthorMutationVariables = Exact<{
  name: Scalars['String']['input'];
  img_url?: InputMaybe<Scalars['String']['input']>;
  about?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAuthorMutation = { __typename?: 'Mutation', createAuthor: { __typename?: 'Author', id: number, name: string, img_url?: string | null, about?: string | null } };

export type GetEntityGroupsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetEntityGroupsQuery = { __typename?: 'Query', bookEntityGroups: Array<{ __typename?: 'BookEntityGroup', id: number, bookId: number, name: string, type?: BookEntityGroupType | null, googleMapId?: string | null, wikiUrl?: string | null, published: boolean }> };

export type GetEntityGroupsGoogleIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEntityGroupsGoogleIdQuery = { __typename?: 'Query', googleBook?: { __typename?: 'Book', id: number, entityGroups: Array<{ __typename?: 'BookEntityGroup', id: number, name: string }> } | null };

export type GetBookEntitiesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetBookEntitiesQuery = { __typename?: 'Query', bookEntities: Array<{ __typename?: 'BookEntity', id: number, word: string, quote: string, quoteStart: number, quoteEnd: number, bookEntityGroupId?: number | null, bookId: number, published: boolean, priority: number }> };


export const GetUserDetailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserDetail"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"emailVerified"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<GetUserDetailQuery, GetUserDetailQueryVariables>;
export const UpdateEntityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateEntityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"entityGroup"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateEntityGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEntityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"entityGroup"},"value":{"kind":"Variable","name":{"kind":"Name","value":"entityGroup"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"bookId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"googleMapId"}},{"kind":"Field","name":{"kind":"Name","value":"wikiUrl"}},{"kind":"Field","name":{"kind":"Name","value":"published"}}]}}]}}]} as unknown as DocumentNode<UpdateEntityGroupMutation, UpdateEntityGroupMutationVariables>;
export const GetBooksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetBooks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"books"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"img_url"}},{"kind":"Field","name":{"kind":"Name","value":"authors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetBooksQuery, GetBooksQueryVariables>;
export const GetAuthorsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAuthors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetAuthorsQuery, GetAuthorsQueryVariables>;
export const UpdateEntityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateEntity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"entity"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateEntityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEntity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"entity"},"value":{"kind":"Variable","name":{"kind":"Name","value":"entity"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"bookId"}},{"kind":"Field","name":{"kind":"Name","value":"word"}},{"kind":"Field","name":{"kind":"Name","value":"quoteStart"}},{"kind":"Field","name":{"kind":"Name","value":"quoteEnd"}},{"kind":"Field","name":{"kind":"Name","value":"quote"}},{"kind":"Field","name":{"kind":"Name","value":"bookEntityGroupId"}},{"kind":"Field","name":{"kind":"Name","value":"published"}},{"kind":"Field","name":{"kind":"Name","value":"priority"}}]}}]}}]} as unknown as DocumentNode<UpdateEntityMutation, UpdateEntityMutationVariables>;
export const DeleteEntityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteEntity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteEntity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"entityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"word"}}]}}]}}]} as unknown as DocumentNode<DeleteEntityMutation, DeleteEntityMutationVariables>;
export const CreateEntityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateEntityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"bookId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createEntityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"bookId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"bookId"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<CreateEntityGroupMutation, CreateEntityGroupMutationVariables>;
export const GetPlaceDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlaceDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"groupDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"googlePlaceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"googleImages"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}},{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"googleurl"}},{"kind":"Field","name":{"kind":"Name","value":"website"}}]}}]}}]} as unknown as DocumentNode<GetPlaceDetailsQuery, GetPlaceDetailsQueryVariables>;
export const CreateBookDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBook"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"bookInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBookInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createBook"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"book"},"value":{"kind":"Variable","name":{"kind":"Name","value":"bookInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"img_url"}},{"kind":"Field","name":{"kind":"Name","value":"google_book_id"}}]}}]}}]} as unknown as DocumentNode<CreateBookMutation, CreateBookMutationVariables>;
export const CreateAuthorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateAuthor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"img_url"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"about"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAuthor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"author"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"img_url"},"value":{"kind":"Variable","name":{"kind":"Name","value":"img_url"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"about"},"value":{"kind":"Variable","name":{"kind":"Name","value":"about"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"img_url"}},{"kind":"Field","name":{"kind":"Name","value":"about"}}]}}]}}]} as unknown as DocumentNode<CreateAuthorMutation, CreateAuthorMutationVariables>;
export const GetEntityGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEntityGroups"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bookEntityGroups"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"bookId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"bookId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"googleMapId"}},{"kind":"Field","name":{"kind":"Name","value":"wikiUrl"}},{"kind":"Field","name":{"kind":"Name","value":"published"}}]}}]}}]} as unknown as DocumentNode<GetEntityGroupsQuery, GetEntityGroupsQueryVariables>;
export const GetEntityGroupsGoogleIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEntityGroupsGoogleId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"googleBook"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"googleBookId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"entityGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetEntityGroupsGoogleIdQuery, GetEntityGroupsGoogleIdQueryVariables>;
export const GetBookEntitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetBookEntities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bookEntities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"bookId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"word"}},{"kind":"Field","name":{"kind":"Name","value":"quote"}},{"kind":"Field","name":{"kind":"Name","value":"quoteStart"}},{"kind":"Field","name":{"kind":"Name","value":"quoteEnd"}},{"kind":"Field","name":{"kind":"Name","value":"bookEntityGroupId"}},{"kind":"Field","name":{"kind":"Name","value":"bookId"}},{"kind":"Field","name":{"kind":"Name","value":"published"}},{"kind":"Field","name":{"kind":"Name","value":"priority"}}]}}]}}]} as unknown as DocumentNode<GetBookEntitiesQuery, GetBookEntitiesQueryVariables>;
import { graphql } from '../gql'

export const GET_USER_DETAIL = graphql(`
    query GetUserDetail {
        userDetails {
            email
            emailVerified
            role
        }
    }
`)

export const UPDATE_ENTITY_GROUP = graphql(`
    mutation UpdateEntityGroup($entityGroup: UpdateEntityGroupInput!) {
        updateEntityGroup(entityGroup: $entityGroup) {
            id
            bookId
            name
            type
            googleMapId
            wikiUrl
            published
        }
    }
`)

export const GET_BOOKS = graphql(`
    query GetBooks {
        books {
            id
            title
            description
            img_url
            authors {
                name
            }
        }
    }
`)

export const GET_AUTHORS = graphql(`
    query GetAuthors {
        authors {
            id
            name
        }
    }
`)

export const UPDATE_ENTITY = graphql(`
    mutation UpdateEntity($entity: UpdateEntityInput!) {
        updateEntity(entity: $entity) {
            id
            bookId
            word
            quoteStart
            quoteEnd
            quote
            bookEntityGroupId
            published
            priority
        }
    }
`)

export const DELETE_ENTITY = graphql(`
    mutation DeleteEntity($id: Int!) {
        deleteEntity(entityId: $id) {
            word
        }
    }
`)

export const CREATE_ENTITY_GROUP = graphql(`
    mutation CreateEntityGroup($bookId: Int!, $name: String!) {
        createEntityGroup(bookId: $bookId, name: $name) {
            id
            name
        }
    }
`)

export const GET_PLACE_DETAILS = graphql(`
    query GetPlaceDetails($id: String!) {
        groupDetails(googlePlaceId: $id) {
            address
            country
            city
            googleImages
            tags
            description
            rating
            longitude
            latitude
            googleurl
            website
        }
    }
`)

export const CREATE_BOOK = graphql(`
    mutation CreateBook($bookInput: CreateBookInput!) {
        createBook(book: $bookInput) {
            title
            description
            img_url
            google_book_id
        }
    }
`)

export const CREATE_AUTHOR = graphql(`
    mutation CreateAuthor($name: String!, $img_url: String, $about: String) {
        createAuthor(
            author: { name: $name, img_url: $img_url, about: $about }
        ) {
            id
            name
            img_url
            about
        }
    }
`)

export const GET_ENTITY_GROUPS = graphql(`
    query GetEntityGroups($id: Int!) {
        bookEntityGroups(bookId: $id) {
            id
            bookId
            name
            type
            googleMapId
            wikiUrl
            published
        }
    }
`)

export const GET_ENTITY_GROUPS_GOOGLE_ID = graphql(`
    query GetEntityGroupsGoogleId($id: String!) {
        googleBook(googleBookId: $id) {
            id
            entityGroups {
                id
                name
            }
        }
    }
`)

export const GET_BOOK_ENTITIES = graphql(`
    query GetBookEntities($id: Int!) {
        bookEntities(bookId: $id) {
            id
            word
            quote
            quoteStart
            quoteEnd
            bookEntityGroupId
            bookId
            published
            priority
        }
    }
`)

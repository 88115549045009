/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query GetUserDetail {\n        userDetails {\n            email\n            emailVerified\n            role\n        }\n    }\n": types.GetUserDetailDocument,
    "\n    mutation UpdateEntityGroup($entityGroup: UpdateEntityGroupInput!) {\n        updateEntityGroup(entityGroup: $entityGroup) {\n            id\n            bookId\n            name\n            type\n            googleMapId\n            wikiUrl\n            published\n        }\n    }\n": types.UpdateEntityGroupDocument,
    "\n    query GetBooks {\n        books {\n            id\n            title\n            description\n            img_url\n            authors {\n                name\n            }\n        }\n    }\n": types.GetBooksDocument,
    "\n    query GetAuthors {\n        authors {\n            id\n            name\n        }\n    }\n": types.GetAuthorsDocument,
    "\n    mutation UpdateEntity($entity: UpdateEntityInput!) {\n        updateEntity(entity: $entity) {\n            id\n            bookId\n            word\n            quoteStart\n            quoteEnd\n            quote\n            bookEntityGroupId\n            published\n            priority\n        }\n    }\n": types.UpdateEntityDocument,
    "\n    mutation DeleteEntity($id: Int!) {\n        deleteEntity(entityId: $id) {\n            word\n        }\n    }\n": types.DeleteEntityDocument,
    "\n    mutation CreateEntityGroup($bookId: Int!, $name: String!) {\n        createEntityGroup(bookId: $bookId, name: $name) {\n            id\n            name\n        }\n    }\n": types.CreateEntityGroupDocument,
    "\n    query GetPlaceDetails($id: String!) {\n        groupDetails(googlePlaceId: $id) {\n            address\n            country\n            city\n            googleImages\n            tags\n            description\n            rating\n            longitude\n            latitude\n            googleurl\n            website\n        }\n    }\n": types.GetPlaceDetailsDocument,
    "\n    mutation CreateBook($bookInput: CreateBookInput!) {\n        createBook(book: $bookInput) {\n            title\n            description\n            img_url\n            google_book_id\n        }\n    }\n": types.CreateBookDocument,
    "\n    mutation CreateAuthor($name: String!, $img_url: String, $about: String) {\n        createAuthor(\n            author: { name: $name, img_url: $img_url, about: $about }\n        ) {\n            id\n            name\n            img_url\n            about\n        }\n    }\n": types.CreateAuthorDocument,
    "\n    query GetEntityGroups($id: Int!) {\n        bookEntityGroups(bookId: $id) {\n            id\n            bookId\n            name\n            type\n            googleMapId\n            wikiUrl\n            published\n        }\n    }\n": types.GetEntityGroupsDocument,
    "\n    query GetEntityGroupsGoogleId($id: String!) {\n        googleBook(googleBookId: $id) {\n            id\n            entityGroups {\n                id\n                name\n            }\n        }\n    }\n": types.GetEntityGroupsGoogleIdDocument,
    "\n    query GetBookEntities($id: Int!) {\n        bookEntities(bookId: $id) {\n            id\n            word\n            quote\n            quoteStart\n            quoteEnd\n            bookEntityGroupId\n            bookId\n            published\n            priority\n        }\n    }\n": types.GetBookEntitiesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetUserDetail {\n        userDetails {\n            email\n            emailVerified\n            role\n        }\n    }\n"): (typeof documents)["\n    query GetUserDetail {\n        userDetails {\n            email\n            emailVerified\n            role\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateEntityGroup($entityGroup: UpdateEntityGroupInput!) {\n        updateEntityGroup(entityGroup: $entityGroup) {\n            id\n            bookId\n            name\n            type\n            googleMapId\n            wikiUrl\n            published\n        }\n    }\n"): (typeof documents)["\n    mutation UpdateEntityGroup($entityGroup: UpdateEntityGroupInput!) {\n        updateEntityGroup(entityGroup: $entityGroup) {\n            id\n            bookId\n            name\n            type\n            googleMapId\n            wikiUrl\n            published\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetBooks {\n        books {\n            id\n            title\n            description\n            img_url\n            authors {\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetBooks {\n        books {\n            id\n            title\n            description\n            img_url\n            authors {\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetAuthors {\n        authors {\n            id\n            name\n        }\n    }\n"): (typeof documents)["\n    query GetAuthors {\n        authors {\n            id\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateEntity($entity: UpdateEntityInput!) {\n        updateEntity(entity: $entity) {\n            id\n            bookId\n            word\n            quoteStart\n            quoteEnd\n            quote\n            bookEntityGroupId\n            published\n            priority\n        }\n    }\n"): (typeof documents)["\n    mutation UpdateEntity($entity: UpdateEntityInput!) {\n        updateEntity(entity: $entity) {\n            id\n            bookId\n            word\n            quoteStart\n            quoteEnd\n            quote\n            bookEntityGroupId\n            published\n            priority\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteEntity($id: Int!) {\n        deleteEntity(entityId: $id) {\n            word\n        }\n    }\n"): (typeof documents)["\n    mutation DeleteEntity($id: Int!) {\n        deleteEntity(entityId: $id) {\n            word\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateEntityGroup($bookId: Int!, $name: String!) {\n        createEntityGroup(bookId: $bookId, name: $name) {\n            id\n            name\n        }\n    }\n"): (typeof documents)["\n    mutation CreateEntityGroup($bookId: Int!, $name: String!) {\n        createEntityGroup(bookId: $bookId, name: $name) {\n            id\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetPlaceDetails($id: String!) {\n        groupDetails(googlePlaceId: $id) {\n            address\n            country\n            city\n            googleImages\n            tags\n            description\n            rating\n            longitude\n            latitude\n            googleurl\n            website\n        }\n    }\n"): (typeof documents)["\n    query GetPlaceDetails($id: String!) {\n        groupDetails(googlePlaceId: $id) {\n            address\n            country\n            city\n            googleImages\n            tags\n            description\n            rating\n            longitude\n            latitude\n            googleurl\n            website\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateBook($bookInput: CreateBookInput!) {\n        createBook(book: $bookInput) {\n            title\n            description\n            img_url\n            google_book_id\n        }\n    }\n"): (typeof documents)["\n    mutation CreateBook($bookInput: CreateBookInput!) {\n        createBook(book: $bookInput) {\n            title\n            description\n            img_url\n            google_book_id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateAuthor($name: String!, $img_url: String, $about: String) {\n        createAuthor(\n            author: { name: $name, img_url: $img_url, about: $about }\n        ) {\n            id\n            name\n            img_url\n            about\n        }\n    }\n"): (typeof documents)["\n    mutation CreateAuthor($name: String!, $img_url: String, $about: String) {\n        createAuthor(\n            author: { name: $name, img_url: $img_url, about: $about }\n        ) {\n            id\n            name\n            img_url\n            about\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetEntityGroups($id: Int!) {\n        bookEntityGroups(bookId: $id) {\n            id\n            bookId\n            name\n            type\n            googleMapId\n            wikiUrl\n            published\n        }\n    }\n"): (typeof documents)["\n    query GetEntityGroups($id: Int!) {\n        bookEntityGroups(bookId: $id) {\n            id\n            bookId\n            name\n            type\n            googleMapId\n            wikiUrl\n            published\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetEntityGroupsGoogleId($id: String!) {\n        googleBook(googleBookId: $id) {\n            id\n            entityGroups {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetEntityGroupsGoogleId($id: String!) {\n        googleBook(googleBookId: $id) {\n            id\n            entityGroups {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetBookEntities($id: Int!) {\n        bookEntities(bookId: $id) {\n            id\n            word\n            quote\n            quoteStart\n            quoteEnd\n            bookEntityGroupId\n            bookId\n            published\n            priority\n        }\n    }\n"): (typeof documents)["\n    query GetBookEntities($id: Int!) {\n        bookEntities(bookId: $id) {\n            id\n            word\n            quote\n            quoteStart\n            quoteEnd\n            bookEntityGroupId\n            bookId\n            published\n            priority\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;